@import "../styles/media-queries.scss";
@import "../styles/_colors";
.card {
  flex: 100%;
  text-align: center;
  border-radius: 1em;
  margin: 1em;
  transition: all 0.7s ease-in-out;

  &:hover {
    background-color: scale-color($color: $darkBlueBg, $lightness: 10%);
    transition: all 0.7s ease-in-out;
  }

  @include sm {
    flex: 30%;
  }

  @include lg {
    flex: 20%;
  }

  img {
    max-width: 90%;
    margin: 0 auto;
  }
  h3 {
    margin: 0 auto;
  }
}
