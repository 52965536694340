@import "../styles/_colors";
@import "../styles/media-queries.scss";

.container {
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  text-align: center;

  .left {
    flex: 100%;
    margin: auto;
    @include md {
      flex: 60%;
      text-align: left;
    }
    p {
      max-width: 80%;
      margin: 1em auto;

      @include md {
        max-width: 60%;
      }
    }
    h1 {
      text-align: center;
      @include md {
        text-align: left;
        max-width: 60%;
        margin: auto;
      }
    }
    .form {
      display: flex;
      justify-content: center;
      //   flex-direction: column;
      flex-wrap: wrap;
      max-width: 90%;
      margin: auto;

      @include md {
        max-width: 60%;
      }

      textarea {
        resize: none;
      }

      input,
      textarea {
        font-family: inherit;
        color: scale-color($color: $darkBlueBg, $lightness: 10%);
        flex: 80%;
        align-items: center;
        padding: 0.5em;
        margin: 0.25em;
        border-radius: 0.5em;
        outline: none;
        border: 2px solid scale-color($color: $darkBlueBg, $lightness: 70%);
        background-color: scale-color($color: $darkBlueBg, $lightness: 70%);
        transition: all 0.7s ease-in-out;

        &::placeholder {
          font-family: inherit;
          color: scale-color($color: $darkBlueBg, $lightness: 30%);
        }

        &:focus {
          border: 2px solid scale-color($color: $darkBlueBg, $lightness: 20%);
          border-radius: 0.5em;
          transition: all 0.7s ease-in-out;
        }
        &:hover {
          border: 2px solid scale-color($color: $darkBlueBg, $lightness: 60%);
          background-color: scale-color($color: $darkBlueBg, $lightness: 60%);
          transition: all 0.7s ease-in-out;
        }
      }

      input[type="submit"] {
        width: 70%;
        border: none;
        color: white;
        background-color: scale-color($color: $darkBlueBg, $lightness: 40%);
        transition: all 0.7s ease-in-out;
        &:hover {
          transition: all 0.7s ease-in-out;
          color: scale-color($color: $darkBlueBg, $lightness: 70%);
          background-color: scale-color($color: $darkBlueBg, $lightness: 20%);
        }
      }

      /* Change Autocomplete styles in Chrome*/
      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      textarea:-webkit-autofill,
      textarea:-webkit-autofill:hover,
      textarea:-webkit-autofill:focus,
      select:-webkit-autofill,
      select:-webkit-autofill:hover,
      select:-webkit-autofill:focus {
        border: 2px solid scale-color($color: $darkBlueBg, $lightness: 60%);
        -webkit-text-fill-color: scale-color(
          $color: $darkBlueBg,
          $lightness: 60%
        );
        //   -webkit-box-shadow: 0 0 0px 1000px #000 inset;
        transition: all 0.7s ease-in-out;
        background-color: scale-color($color: $darkBlueBg, $lightness: 20%);
        color: scale-color($color: $darkBlueBg, $lightness: 10%);
      }
    }
  }

  .right {
    flex: 100%;
    margin: auto 0;
    text-align: center;

    @include md {
      flex: 40%;
    }

    svg {
      width: 70%;
      height: auto;
      margin: 1.5em;

      @include md {
        margin-top: 0em;
        width: 100%;
      }
    }
  }
}
