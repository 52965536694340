@import "../styles/_colors";
html {
  font-family: Helvetica, Arial, sans-serif;

  h1 {
    font-size: 3em;
  }
  a {
    text-decoration: none;
    color: inherit;
  }
}

.background {
  position: fixed;
  background-color: $darkBlueBg;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  padding: 0;
  margin: 0;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 90vw;
  height: 100vh;
  margin: auto;
  header {
    flex: 10%;
  }
  main {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    flex: 85%;
  }
  footer {
    flex: 5%;
  }
}
main {
  color: white;
}
