@import "../styles//media-queries.scss";

.container {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  // flex-direction: row-reverse;
  margin: auto;

  @include sm {
    text-align: left;
    // flex-direction: row;
  }

  .right {
    flex: 100%;
    margin: auto;
    @include sm {
      flex: 60%;
      text-align: left;
    }
    p {
      max-width: 80%;
      margin: 0 auto;

      @include sm {
        max-width: 60%;
      }
    }
    h1 {
      text-align: center;
      margin: 1em auto 0 auto;
      @include sm {
        text-align: left;
        max-width: 60%;
        margin: 0 auto;
      }
    }
  }

  .left {
    flex: 100%;
    margin: auto;
    text-align: center;

    @include sm {
      flex: 40%;
    }

    svg {
      margin-top: 1em;
      width: 60%;
      height: auto;

      @include sm {
        margin-top: 0;
        width: 60%;
      }
      @include lg {
        width: 60%;
      }
    }
  }
}
