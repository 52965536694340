@import "../styles/_colors";
.container {
  margin: 1em 0;
  display: flex;
  a {
    text-align: center;
    color: white;
    text-decoration: none;
    flex: 25%;
    transition: all 0.7s ease-in-out;

    &:hover {
      color: scale-color($color: $darkBlueBg, $lightness: 70%);
      transition: all 0.7s ease-in-out;
    }
  }

  .active {
    color: scale-color($color: $darkBlueBg, $lightness: 70%);
    transition: all 0.7s ease-in-out;
  }
}
