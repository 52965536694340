@import "../styles/media-queries.scss";

.container {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  margin: auto;

  @include sm {
    text-align: left;
  }

  .left {
    flex: 100%;
    margin: auto;
    @include sm {
      flex: 60%;
      text-align: left;
    }
    p {
      max-width: 80%;
      margin: auto;

      @include sm {
        max-width: 60%;
      }
    }
    h1 {
      text-align: center;
      @include sm {
        text-align: left;
        max-width: 60%;
        margin: 0.5em auto;
      }
    }
  }

  .right {
    flex: 100%;
    @include sm {
      flex: 40%;
    }

    svg {
      margin-top: 1em;
      width: 40%;
      height: auto;

      @include sm {
        margin-top: 0;
        width: 50%;
      }
      @include lg {
        width: 40%;
      }
    }
  }
}
