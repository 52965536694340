@import "../../styles/media-queries.scss";

.container {
  display: flex;
  flex-wrap: wrap;
  //   flex-direction: column;

  .title {
    flex: 100%;
    text-align: center;
  }
  .list {
    flex: 90%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}
